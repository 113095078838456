<template>
  <div>
    <Backbar
      :title="$t('howtoPage.title')"
      @onBackClick="$router.go(-1)"
    />
    <div class="content">
      <HowToTh v-if="$i18n.locale === 'th'"/>
      <HowToEn v-else-if="$i18n.locale === 'en'"/>
    </div>
  </div>
</template>

<script>
import Backbar from '@/components/Backbar.vue'
import HowToTh from '@/components/how-to/HowToTh.vue'
import HowToEn from '@/components/how-to/HowToEn.vue'

export default {
  name: 'Home',
  components: {
    Backbar,
    HowToTh,
    HowToEn
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 18px;
}
</style>
